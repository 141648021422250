import React, {useEffect, useState} from 'react'
import './App.css';
import AOS from 'aos'
import PulseLoader from "react-spinners/PulseLoader";
import Navbar from './components/Navbar';
import Sidelinks from './components/Sidelinks';
import Landing from './components/Landing';
import About from './components/About';
import Projects from './components/Projects'
import Contact from './components/Contact'
import Footer from './components/Footer';


function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return (
        <div className="App">
            {
                loading ?
                <div className='loadingScreen'>
                <PulseLoader
                    color={"#cfcfcf"}
                    loading={loading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>

                :
            <div>
            <Navbar></Navbar>
            <Landing></Landing>
            <About></About>
            <Projects></Projects>
            <Contact></Contact>
            <Footer></Footer>
            <Sidelinks></Sidelinks>
            </div>
            }
        </div>
    );
}

export default App;
