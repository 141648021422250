import React, {useEffect} from 'react'
import AOS from 'aos'
import '../styles/Projects.css'
import 'aos/dist/aos.css'
import JAATE from '../imgs/JAATE.png'
import peaceOfMind from '../imgs/peaceOfMind.png'
import socialMediaAPI from '../imgs/socialMediaAPI.png'


function Projects() {
    useEffect(()=> {
        AOS.init({duration: 3000})
    }, [])
    return (
        <div id="projects" data-aos='fade-out' data-aos-once='true'>
            <h3>Projects I've Worked On</h3>
            <ul id='projectUl'>
                <li>
                    <div className='projectContainer'>
                        <div className='projectImage' data-aos='flip-down' data-aos-once='true'>
                            <a href='https://github.com/AndreaRene/Peace_of_Mind'>
                                <img className='projectPreview' src={peaceOfMind} alt='Peace of Mind Website'></img>
                            </a>
                        </div>
                        <div className='projectText' data-aos='fade-left' data-aos-once='true'>
                            <h2>Peace of Mind</h2>
                            <div className='projectImageSmall' data-aos='flip-down' data-aos-once='true'>
                                <a href='https://github.com/AndreaRene/Peace_of_Mind'>
                                    <img className='projectPreview' src={peaceOfMind} alt='Peace of Mind Website'></img>
                                </a>
                            </div>
                            <p>Peace of Mind is a website that will allow anyone with a need to connect to others regarding mental health and support. We provide a safe space for everyone to discuss their feelings without fear of retaliation.</p>
                            <a className='projectLink' href='https://github.com/AndreaRene/Peace_of_Mind'>
                                <i className="fa-brands fa-github fa-lg"></i>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='projectContainer'>
                        <div className='projectText' data-aos='fade-right' data-aos-once='true'>
                            <h2>Just Another ANOTHER Text Editor (JAATE)</h2>
                            <div className='projectImageSmall' data-aos='flip-up' data-aos-once='true'>
                                <a href='https://github.com/ParksFG/Just-Another-ANOTHER-Text-Editor'>
                                    <img className='projectPreview' src={JAATE} alt='JAATE Preview'></img>
                                </a>
                            </div>
                            <p>This is a simple text editor web app. I made this to test working with web apps, specifically webpacks. The app itself can be downloaded and has basic code checking.</p>
                            <a className='projectLink' href='https://github.com/ParksFG/Just-Another-ANOTHER-Text-Editor'>
                                <i className="fa-brands fa-github fa-lg"></i>
                            </a>
                        </div>
                        <div className='projectImage' data-aos='flip-up' data-aos-once='true'>
                            <a href='https://github.com/ParksFG/Just-Another-ANOTHER-Text-Editor'>
                                <img className='projectPreview' src={JAATE} alt='JAATE Preview'></img>
                            </a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='projectContainer'>
                        <div className='projectImage' data-aos='flip-down' data-aos-once='true'>
                            <a href='https://github.com/ParksFG/Social-Network-API'>
                                <img className='projectPreview' src={socialMediaAPI} alt='JAATE Preview'></img>
                            </a>
                        </div>
                        <div className='projectText' data-aos='fade-left' data-aos-once='true'>
                            <h2>Social Media Demo API</h2>
                            <div className='projectImageSmall' data-aos='flip-down' data-aos-once='true'>
                                <a href='https://github.com/ParksFG/Social-Network-API'>
                                    <img className='projectPreview' src={socialMediaAPI} alt='JAATE Preview'></img>
                                </a>
                            </div>
                            <p>This project is an API created for a thought-based social network. I made this project to primarily test my skills with mongodb, more specifically mongoose.</p>
                            <a className='projectLink' href='https://github.com/ParksFG/Social-Network-API'>
                                <i className="fa-brands fa-github fa-lg"></i>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
  );
}

export default Projects;
