import '../styles/Navbar.css'
import React from 'react'
import Resume from '../components/files/resume.pdf'

function Navbar() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
        } else {
        document.getElementById("navbar").style.top = "-100px";
        }
    prevScrollpos = currentScrollPos;
    }

    const [checked, setChecked] = React.useState(false)
    const handleCheck = () => {
        setChecked(!checked)
    }

    const top = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div id="navbar">
            <input type='checkbox' id='check' checked={checked} onClick={handleCheck}></input>
            <label htmlFor='check' className='checkBtn'>
                <i className='fas fa-bars' onClick={handleCheck}></i>
            </label>
            <a onClick={top}><h1>Parker Gay</h1></a>
            <ul id='navbarUl'>
                <li>
                    <a href={Resume} id='resumeBtn'>Resume</a>
                </li>
                <li>
                    <a onClick={handleCheck} href="#contact" className='navbarA'>Contact</a>
                </li>
                <li>
                    <a onClick={handleCheck} href="#projects" className='navbarA'>Projects</a>
                </li>
                <li>
                    <a onClick={handleCheck} href="#about" className='navbarA'>About</a>
                </li>
            </ul>
        </div>
        
  );
}

export default Navbar;
