import '../styles/Sidelinks.css'

function Sidelinks() {
    return (
        <div id="Sidelinks" data-aos='zoom-in' data-aos-once='true'>
            <ul id='SidelinksUl'>
                <li className='SidelinksLi'>
                    <a className='SidelinksA' href='https://github.com/ParksFG'>
                        <i className="fa-brands fa-github fa-lg"></i>
                    </a>
                </li>
                <li id='SidelinksLast' className='SidelinksLi'>
                    <a className='SidelinksA' href='https://www.linkedin.com/in/parkerfgay/'>
                        <i className="fa-brands fa-linkedin fa-lg"></i>
                    </a>
                </li>
            </ul>
        </div>
        
  );
}

export default Sidelinks;
