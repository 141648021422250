import React, {useEffect} from 'react'
import AOS from 'aos'
import '../styles/Contact.css'
import 'aos/dist/aos.css'

function Contact() {
    useEffect(()=> {
        AOS.init({duration: 3000})
    }, [])
    return (
        <div id="contact" data-aos='zoom-out' data-aos-once='true'>
            <h3>Contact Me</h3>
            <p>I'm currently looking for more opportunities to work with a dynamic and growing company.  Let me know if you want to get in touch.</p>
            <ul id='contactUl'>
                <li className='contactLi'>
                    <h2>Email:</h2>
                    <p>ParkerFGay@gmail.com</p>
                </li>
                <li className='contactLi'>
                    <h2>GitHub:</h2>
                    <a href='https://github.com/ParksFG'>https://github.com/ParksFG</a>
                </li>
                <li className='contactLi'>
                    <h2>LinkedIn:</h2>
                    <a href='https://www.linkedin.com/in/parkerfgay/'>https://www.linkedin.com/in/parkerfgay/</a>
                </li>
            </ul>
        </div>
  );
}

export default Contact;
