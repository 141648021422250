import '../styles/Footer.css'

function Footer() {
    return (
        <div id="footer">
            <p>Website created by Parker Gay</p>
        </div>
  );
}

export default Footer;