import React, {useEffect} from 'react'
import AOS from 'aos'
import '../styles/About.css'
import 'aos/dist/aos.css'
import selfPortrait from '../imgs/selfPortrait.png'

function About() {
    useEffect(()=> {
        AOS.init({duration: 3000})
    }, [])
    return (
        <div id="about" data-aos='fade-right' data-aos-once='true'>
            <h3>About Me</h3>
            <div id='aboutBody'>
                <p>
                My name is Parker Gay.  I'm a 23 year old web developer who's been obsessed with computers since the day I could walk.  In high school, I started creating websites for my friend's YouTube channels.  In college, I studied mechanical engineering but was provided the opportunity to join an IT start up as the lead web developer. <br></br><br></br> While the experience with the start up provided great “real world” experience, I realized that my self-taught web development skills could be enhanced.  I enrolled in the University of Texas @Austin web development bootcamp.  This experience provided me the opportunity to work with web technologies that diversified my web development. <br></br><br></br>
                Technologies I'm experienced with:
                <div id='aboutLists'>
                    <ul id='ul1' className='aboutUl'>
                        <li>ReactJS</li>
                        <li>JavaScript (ES6+)</li>
                        <li>APIs</li>
                    </ul>
                    <ul id='ul2' className='aboutUl'>
                        <li>WebApps</li>
                        <li>MongoDB</li>
                        <li>MySQL</li>
                    </ul>
                </div>
                </p>
                <img src={selfPortrait} alt='Self Portrait'>
                </img>
            </div>
            <ul>

            </ul>
        </div>
  );
}

export default About;
