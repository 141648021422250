import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../styles/Landing.css'

function Landing() {
    useEffect(()=> {
        AOS.init({duration: 10000})
    }, [])
    return (
        <div id="landing" data-aos='zoom-in' data-aos-once='true'>
            <h5>Hello, my name is</h5>
            <h1>Parker Gay</h1>
            <h2>I'm a Full-Stack Developer</h2>
            <p>I am a life-long computer nerd who understands how a unique web presence with a focus on user experience can not only complement, but strengthen a business strategy.</p>
        </div>
  );
}

export default Landing;
